import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue";
var _hoisted_1 = {
  class: "signature-display-board"
};
var _hoisted_2 = ["innerHTML"];
var _hoisted_3 = {
  class: "signature-display-board__main"
};
var _hoisted_4 = ["src"];
import { ref, reactive, onMounted, nextTick } from "vue";
export default {
  __name: 'SignatureDisplayBoard',
  props: {
    src: String,
    title: String
  },
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.title ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "signature-display-board__title",
        innerHTML: __props.title
      }, null, 8, _hoisted_2)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
        src: __props.src,
        alt: ""
      }, null, 8, _hoisted_4)])]);
    };
  }
};